
(() => {
	console.log("Hello World");

	// var scene = $('#scene').get(0);
	// var parallaxInstance = new Parallax(scene, {
	// 	pointerEvents: true
	// });

	$('.name-copy').click(function(){ 
		console.log('jaksdfjaklsdf');
		window.location.replace("index.html");
	});


	var controller = new ScrollMagic.Controller();

	$('img.thumbnail').each(function(){
		var ourScene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 0.9	
		})
		.setClassToggle(this,'fade-in').addTo(controller);

	});

	TweenMax.from(".about-info", .5, {opacity: 0, scale:0, ease:Bounce.easeOut, delay: 1});

	





})();


